import React from 'react'
import Layout from '../components/layout'
import Map from '../components/Map'
import Seo from '../components/seo'

const ContactUs=() => (
<Layout>
<Seo title='Brit Veteriner Diyet Mamaları İletişim' desc='Brit Veteriner Diyet Mama Satın almak veya sorularınız için iletişim bilgileri'/>
<section>
<div className="container">
    <div className="section__content">
        <div className="py-4">
            <div className="mb-4 mb-lg-6">
                <h1 className="h2 text-center">İLETİŞİM</h1>
            </div>
            <div className="row">
                <div className="col-12 col-lg-4">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-12 mb-4">
                            <address>
                                <h2 className="h3">İLGİLİ</h2>
                                <p>Süleyman Hancı<br/>
                                    <em className="text-muted">satış müdürü</em></p>
                                <p>
                                    email: <strong><a href="mailto:suleyman.hanci@atlaspet.com.tr">suleyman.hanci@atlaspet.com.tr</a></strong><br/>
                                    phone: <strong><a href="tel:+905539346125" className="text-nowrap">+90 553 934 61 25</a></strong>
                                </p>
                            </address>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-12 mb-4">
                            <h2 className="h3">ŞİRKET BİLGİLERİ</h2>
                            Atlaspet Pet Ürünleri Pazarlama ve Ticaret A.Ş.<br/>
                            Göktürk Cad. No: 2 D-7<br/> Göktürk Merkez Mahallesi, Eyüp, İstanbul<br/> Türkiye
                            <p>Tel: <strong><a href="tel:+902128070140" className="text-nowrap">+90 212 807 01 40</a></strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-8">
                    <div className="map" data-aos="fade-up">
                    <Map
                    id="atakMap"
                    options={{
                      center: { lat: 41.176905, lng: 28.890119 },
                      zoom: 15,
                      styles:[
                        {
                          "elementType": "geometry",
                          "stylers": [
                            {
                              "color": "#f5f5f5"
                            }
                          ]
                        },
                        {
                          "elementType": "labels.icon",
                          "stylers": [
                            {
                              "visibility": "off"
                            }
                          ]
                        },
                        {
                          "elementType": "labels.text.fill",
                          "stylers": [
                            {
                              "color": "#616161"
                            }
                          ]
                        },
                        {
                          "elementType": "labels.text.stroke",
                          "stylers": [
                            {
                              "color": "#f5f5f5"
                            }
                          ]
                        },
                        {
                          "featureType": "administrative.land_parcel",
                          "elementType": "labels.text.fill",
                          "stylers": [
                            {
                              "color": "#bdbdbd"
                            }
                          ]
                        },
                        {
                          "featureType": "poi",
                          "elementType": "geometry",
                          "stylers": [
                            {
                              "color": "#eeeeee"
                            }
                          ]
                        },
                        {
                          "featureType": "poi",
                          "elementType": "labels.text.fill",
                          "stylers": [
                            {
                              "color": "#757575"
                            }
                          ]
                        },
                        {
                          "featureType": "poi.park",
                          "elementType": "geometry",
                          "stylers": [
                            {
                              "color": "#e5e5e5"
                            }
                          ]
                        },
                        {
                          "featureType": "poi.park",
                          "elementType": "labels.text.fill",
                          "stylers": [
                            {
                              "color": "#9e9e9e"
                            }
                          ]
                        },
                        {
                          "featureType": "road",
                          "elementType": "geometry",
                          "stylers": [
                            {
                              "color": "#ffffff"
                            }
                          ]
                        },
                        {
                          "featureType": "road.arterial",
                          "elementType": "labels.text.fill",
                          "stylers": [
                            {
                              "color": "#757575"
                            }
                          ]
                        },
                        {
                          "featureType": "road.highway",
                          "elementType": "geometry",
                          "stylers": [
                            {
                              "color": "#dadada"
                            }
                          ]
                        },
                        {
                          "featureType": "road.highway",
                          "elementType": "labels.text.fill",
                          "stylers": [
                            {
                              "color": "#616161"
                            }
                          ]
                        },
                        {
                          "featureType": "road.local",
                          "elementType": "labels.text.fill",
                          "stylers": [
                            {
                              "color": "#9e9e9e"
                            }
                          ]
                        },
                        {
                          "featureType": "transit.line",
                          "elementType": "geometry",
                          "stylers": [
                            {
                              "color": "#e5e5e5"
                            }
                          ]
                        },
                        {
                          "featureType": "transit.station",
                          "elementType": "geometry",
                          "stylers": [
                            {
                              "color": "#eeeeee"
                            }
                          ]
                        },
                        {
                          "featureType": "water",
                          "elementType": "geometry",
                          "stylers": [
                            {
                              "color": "#c9c9c9"
                            }
                          ]
                        },
                        {
                          "featureType": "water",
                          "elementType": "labels.text.fill",
                          "stylers": [
                            {
                              "color": "#9e9e9e"
                            }
                          ]
                        }
                      ],
                    }}
                    onMapLoad={map => {
                      var marker = new window.google.maps.Marker({
                        position: { lat: 41.176905, lng: 28.890119 },
                        map: map,
                        title: 'Atlaspet Pet Ürünleri Paz. ve Tic. A.Ş.',
                      })
                    }}
                  />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
</Layout>
)

export default ContactUs